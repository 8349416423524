import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import BasicPlan from "../../assets/basic-plan.png";
import StandardPlan from "../../assets/StandardPlan.jpg";
import PremiumPlan from "../../assets/PremiumPlan.jpg";
import { motion } from "framer-motion";

const WebsitePackagePlan = () => {
  return (
    <>
      <div className="plan-detail text-center pl-52 pr-52">
        <h3>Website Package Plans</h3>
        <p>
          Find the perfect plan for your needs with our straightforward pricing
          options. Discover competitive rates and unlock the exceptional value
          we offer for our top-notch services.
        </p>
      </div>
      <div className="package-plan justify-center lg:flex flex-col lg:flex-row">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: false, amount: 0.5 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -200 }}
          className="plan-detail md:w-1/3">
          <h3>Package Plan</h3>
          <p>Development Fees (One Time Charges)</p>
          <p>Website Pages</p>
          <p>Web Hosting</p>
          <p>SSD Storage</p>
          <p>Bandwidth</p>
          <p>Free SSL</p>
          <p>Website Optimization</p>
          <p>Google Analytics / Social Media Integration</p>
          <p>Maintenance & Support Plan (Annually)</p>
          <p>Domain Fees (Annually)</p>
        </motion.div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <motion.img
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            exit={{ opacity: 0, y: 200 }}
            className="plan-img"
            src={BasicPlan}
            alt="basic-plan"
          />
          <motion.img
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, y: 200 }}
            className="standard-img"
            src={StandardPlan}
            alt="standard-plan"
          />
          <motion.img
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, y: 200 }}
            className="plan-img -z-0"
            src={PremiumPlan}
            alt="premium-plan"
          />
        </div>
      </div>
    </>
  );
};

export default WebsitePackagePlan;
